<template>
  <div>
    <button class="partner-logo-wrapper" type="button" @click="$modal.show('partnerRedirectModal')" v-if="partner">
      <img class="partner-logo" :src="src" :alt="alt">
    </button>
    <modal
      name="partnerRedirectModal"
      height="auto"
      :scrollable="true"
      :width="560"
      :pivotY="0.2"
    >
      <div class="text-center" v-if="partner">
        <em class="title text-center m-b-60">{{ partner.name }} partner site</em>
        <p class="text-lg text-center m-b-50">You are about to leave Swoop and will be redirect back to <span class="partnerName">{{ partner.name }}</span>.</p>
        <a
          class="btn btn-primary m-r-15"
          :href="redirectLink"
          rel="noopener"
        >
          Continue
        </a>
        <button
          class="btn"
          type="button"
          @click="$modal.hide('partnerRedirectModal')"
        >
          Close
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: 'PartnerLogo',
  props: {
    partnerName: {
      type: String,
      required: true
    },
    isDarkLogo: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      partners: {
        umi: { name: 'Umi', source: 'partnerUmi.svg', sourceDark: 'partnerUmi-dark.svg', link: 'https://www.weareumi.co.uk' }
      }
    }
  },
  computed: {
    partner () {
      if (this.partnerName in this.partners) return this.partners[this.partnerName]
      return null
    },
    redirectLink () {
      if (!this.partner) return ''
      return this.partner.link
    },
    alt () {
      if (!this.partner) return ''
      return `${this.partner.name} logo`
    },
    src () {
      if (!this.partner) return ''
      if (this.isDarkLogo) {
        return `/img/partners-logos/${this.partner.sourceDark}`
      }
      return `/img/partners-logos/${this.partner.source}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/swoop/variables';

$logo-height: 34px;

.v--modal {
  p {
    color: $color-main;
  }
  em {
    text-transform: capitalize;
  }
  .partnerName {
    text-transform: uppercase;
  }
}
.partner-logo-wrapper {
  position: relative;
  background: none;
  border: none;
  padding: 0;
  margin: 0 15px 0 16px;
  &::after {
    $height: 15px;
    content: '';
    position: absolute;
    border-left: 1px solid $default-border-color;
    height: $height;
    opacity: 0.5;
    display: inline-block;
    top: calc((#{$logo-height} - #{$height}) / 2);
    left: -16px;
  }
}
.partner-logo {
  cursor: pointer;
  width: auto;
  height: $logo-height;
}
</style>
