<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-offset-1 col-sm-10 col-md-offset-3 col-md-6">
        <div class="top-logo m-b-30">
          <SwoopLogo class="top-main-logo" />
          <PartnerLogo class="top-partner-logo" :partnerName="partnerName" :isDarkLogo="true" v-if="partnerName" />
        </div>
        <div class="card card__shadow m-b-25">
          <div class="m-b-30">
            <h2 class="title">Your password has been changed. You can continue using the application.</h2>
          </div>
          <p class="text-lg">You will be redirected to the <router-link :to="{ name: 'dashboard' }">Swoop</router-link> page in ({{ redirectIn }}) seconds.</p>
        </div>
        <div class="row">
          <div class="col-sm-12 text-center">
            <router-link :to="{ name: 'login' }">Sign In</router-link>
            <span class="text-secondary inline-middle h4 m-l-5 m-b-0 m-r-5">&middot;</span>
            <router-link :to="{ name: 'sign-up-form-type' }">Create an Account</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PartnerLogo from '@/components/PartnerLogo'
import SwoopLogo from '@/components/SwoopLogo'

export default {
  name: 'changePartnerPasswordVerification',
  components: {
    PartnerLogo,
    SwoopLogo
  },
  data() {
    return {
      redirectIn: 10,
      intervalId: null,
      partnerName: ''
    }
  },
  methods: {
    redirect() {
      this.intervalId = setInterval(() => {
        this.redirectIn--
        if (this.redirectIn < 1) {
          clearInterval(this.intervalId)
          setTimeout(() => {
            this.$router.push({ name: 'dashboard' })
          }, 100)
        }
      }, 1000)
    }
  },
  mounted() {
    this.redirect()
  },
  beforeMount() {
    const { partner } = this.$route.query
    if (partner) {
      this.partnerName = partner
    }
  },
  destroyed() {
    clearInterval(this.intervalId)
  }
}
</script>

<style lang="scss" scoped>
.top-logo {
  display: flex;
  justify-content: center;
  .top-main-logo {
    margin: 0 16px;
  }
}
</style>
